import React, { useState } from "react";
import Loading from "../../component/Loading";
import AlertBox from "../../component/AlertBox";
import PasswordField from "../../component/PasswordField";
import ButtonCompo from "../../component/ButtonCompo";
import { axiosInstance } from "../../constant";

function STChangePassword() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  // change password
  async function changePassword() {
    if (password === "") {
      setOpen(true);
      setSuccess(false);
      setMessage("Password is required");
      return null;
    }
    const data = {
      password: password,
    };
    setLoading(true);
    try {
      const res = await axiosInstance.post(
        "/api/v1/staff/password/change",
        data
      );
      setOpen(true);
      setSuccess(true);
      setMessage("Password changed successfully");
    } catch (error) {
      console.log(error)
      setOpen(true);
      setSuccess(false);
      setMessage(error?.response?.data?.message || "Please try again later");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <Loading />}
      <div className="w-full flex justify-center items-center flex-col">
        {success !== undefined && (
          <AlertBox
            open={open}
            setOpen={setOpen}
            success={success}
            title={message}
          />
        )}
        <div className="w-[30rem] flex justify-center items-center flex-col mt-5">
          <section className="text-center mb-3">
            <h1 className="text-[30px] font-semibold text-orange-800">
              Change Password
            </h1>
          </section>
          <PasswordField
            onChange={(event) => setPassword(event?.target?.value)}
            label="New Password"
          />
          <div className="w-full flex justify-end mt-5">
            <ButtonCompo variant={"primary"} onClick={changePassword}>
              Save
            </ButtonCompo>
          </div>
        </div>
      </div>
    </>
  );
}

export default STChangePassword;
