import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./page/Layout";
import Landing from "./page/Landing";
import Signin from "./page/Signin";
import Signup from "./page/Signup";
import { axiosInstance } from "./constant";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "./store/auth.slice";
import MyProfile from "./page/MyProfile";
import SADashboard from "./page/Superadmin/SADashboard";
import SASignin from "./page/Superadmin/SASignin";
import { superadminLogin, superadminLogout } from "./store/superadmin.slice";
import SALayout from "./page/Superadmin/SALayout";
import logo from "../src/assets/companyLogo.png";
import SAAddStaff from "./page/Superadmin/SAAddStaff";
import { staffLogin, staffLogout } from "./store/staff.slice";
import STDashboard from "./page/Staff/STDashboard";
import STSignin from "./page/Staff/STSignin";
import STLayout from "./page/Staff/STLayout";
import STAddProduct from "./page/Staff/STAddProduct";
import STCatagory from "./page/Staff/STCatagory";
import STAllProducts from "./page/Staff/STAllProducts";
import STEditProduct from "./page/Staff/STEditProduct";
import ProductPage from "./page/ProductPage";
import Blog from "./page/Blog";
import HealthTips from "./page/HealthTips";
import AboutUs from "./page/AboutUs";
import ContactUs from "./page/ContactUs";
import ProductDetails from "./page/ProductDetails";
import CartPage from "./page/CartPage";
import STCoupons from "./page/Staff/STCoupons";
import STCharges from "./page/Staff/STCharges";
import SACompanySetting from "./page/Superadmin/SACompanySetting";
import STOrderhistory from "./page/Staff/STOrderhistory";
import STSingleOrder from "./page/Staff/STSingleOrder";
import STBanners from "./page/Staff/STBanners";
import SingleOrder from "./page/SingleOrder";
import STVideoReview from "./page/Staff/STVideoReview";
import STContact from "./page/Staff/STContact";
import STUsers from "./page/Staff/STUsers";
import STNewOrder from "./page/Staff/STNewOrder";
import TermsAndConds from "./page/TermsAndConds";
import CatagoryPage from "./page/CatagoryPage";
import PrivacyAndPolicy from "./page/PrivacyAndPolicy";
import RefundAndReturns from "./page/RefundAndReturns";
import ShippingAndDelivery from "./page/ShippingAndDelivery";
import STReport from "./page/Staff/STReport";
import STProductsReport from "./page/Staff/STProductsReport";
import ForgotPassword from "./page/ForgotPassword";
import STChangePassword from "./page/Staff/STChangePassword";

function App() {
  const [loading, setLoading] = useState(false);
  const isAuthenticatedUser = useSelector(
    (state) => state.auth?.isAuthenticated
  );
  const isSuperadminAuthenticated = useSelector(
    (state) => state.superadminSlice?.isSuperadminAuthenticated
  );
  const isStaffAuthenticated = useSelector(
    (state) => state.superadminSlice?.isStaffAuthenticated
  );
  const reduxReload = useSelector((state) => state.reload?.reload);
  const dispatch = useDispatch();

  async function getUser() {
    try {
      setLoading(true);
      const res = await axiosInstance.get("/api/v1/user/get");
      dispatch(login({ userData: res.data?.data }));
    } catch (error) {
      // console.log(error);
      dispatch(logout());
    } finally {
      setLoading(false);
    }
  }

  async function getSuperadmin() {
    try {
      setLoading(true);
      const res = await axiosInstance.get("/api/v1/superadmin/get");
      // console.log(res.data?.data);
      dispatch(superadminLogin({ superadminData: res.data?.data }));
    } catch (error) {
      // console.log(error);
      dispatch(superadminLogout());
    } finally {
      setLoading(false);
    }
  }

  async function getStaff() {
    try {
      setLoading(true);
      const res = await axiosInstance.get("/api/v1/staff/get");
      // console.log(res.data?.data);
      dispatch(staffLogin({ staffData: res.data?.data }));
    } catch (error) {
      // console.log(error);
      dispatch(staffLogout());
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getSuperadmin();
  }, [isSuperadminAuthenticated]);

  useEffect(() => {
    getStaff();
  }, [isStaffAuthenticated]);

  useEffect(() => {
    getUser();
  }, [isAuthenticatedUser, reduxReload]);

  if (loading) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <img src={logo} className="w-[10rem]" alt="" />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Landing />} />
          <Route path="profile" element={<MyProfile />} />
          <Route path="shop" element={<ProductPage />} />
          <Route path="catagories" element={<CatagoryPage />} />
          <Route path="blog" element={<Blog />} />
          <Route path="health-tips" element={<HealthTips />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="product/:productId" element={<ProductDetails />} />
          <Route path="cart" element={<CartPage />} />
          <Route path="order/get/:orderId" element={<SingleOrder />} />
          <Route path="terms-and-conditions" element={<TermsAndConds />} />
          <Route path="privacy-and-policy" element={<PrivacyAndPolicy />} />
          <Route path="refund-and-returns" element={<RefundAndReturns />} />
          <Route path="shipping-and-delivery" element={<ShippingAndDelivery />} />
        </Route>
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/superadmin/signin" element={<SASignin />} />
        <Route path="/staff/signin" element={<STSignin />} />
        <Route path="/superadmin/" element={<SALayout />}>
          <Route path="dashboard" element={<SADashboard />} />
          <Route path="staff/add" element={<SAAddStaff />} />
          <Route path="company/settings" element={<SACompanySetting />} />
        </Route>
        <Route path="/staff/" element={<STLayout />}>
          <Route path="dashboard" element={<STDashboard />} />
          <Route path="product/add" element={<STAddProduct />} />
          <Route path="product/edit/:productId" element={<STEditProduct />} />
          <Route path="catagories" element={<STCatagory />} />
          <Route path="products" element={<STAllProducts />} />
          <Route path="coupons" element={<STCoupons />} />
          <Route path="charges" element={<STCharges />} />
          <Route path="orders" element={<STOrderhistory />} />
          <Route path="banners" element={<STBanners />} />
          <Route path="order/get/:orderId" element={<STSingleOrder />} />
          <Route path="product/video/:productId" element={<STVideoReview />} />
          <Route path="contact" element={<STContact />} />
          <Route path="users" element={<STUsers />} />
          <Route path="orders/new" element={<STNewOrder />} />
          <Route path="reports" element={<STReport />} />
          <Route path="change/password" element={<STChangePassword />} />
          <Route path="products/reports" element={<STProductsReport />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
